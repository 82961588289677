import { Component, ComponentRef, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


import { environment } from 'src/environments/environment';
import { PopupService } from 'src/app/services/popup.service';
import { WalletConnectService } from 'src/app/services/wallet-connect.service';
import { EventService } from 'src/app/services/event.service';
import { SwapStatus, convertNum, getParamWithoutCookie } from 'src/app/shared/constants/app-enums';
import { ConnectComponent } from 'src/app/modals/connect/connect.component';
import { ReferComponent } from 'src/app/modals/refer/refer.component';
import { ClaimComponent } from 'src/app/modals/claim/claim.component';
import { SwapComponent } from 'src/app/components/swap/swap.component';




@Component({
  selector: 'app-buy-widget',
  templateUrl: './buy-widget.component.html',
  styleUrls: ['./buy-widget.component.scss']
})

export class BuyWidgetComponent implements OnInit, OnDestroy {

  @ViewChild(SwapComponent) swapComp: any;
  private subscriptions: Subscription[] = [];

  lang: any = localStorage.getItem('currentlang');
  isMobile = (window.matchMedia && window.matchMedia('(max-device-width: 1024px)').matches) || screen.width <= 1024;

  modalRef?: BsModalRef;
  emailId = '';
  tokenSold = 0;
  totalTokens = 0;
  hashKey: string = '';
  windowSize: number = 0;
  usdRaised = 0;
  currentStep = 0;
  selectedLanguage: any;
  userStaked: number = 0;
  currentLanguage: any;
  connectInterval: any;
  usdtBal = 0;
  ethBal = 0;
  userClaimable: number = 0;
  userEthClaimable: number = 0;
  userBscClaimable: number = 0;
  refreshId: any;
  refreshRate: number = 1000;
  dataLoaded: boolean = false;
  oversale: boolean = false;
  futureToken: number = 0;
  salePrice: number = 0.0337;
  listPrice: number = 0.0337;
  liveCounter: any;
  isLive: boolean = true;
  claimMode: boolean = false;
  swapMode: string = 'native';
  isNative: boolean = true;
  isWert: boolean = false;

  SwapStatus = SwapStatus;
  timeInterval: any;
  showBW: boolean = false;


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = (window.matchMedia && window.matchMedia('(max-device-width: 1024px)').matches) || screen.width <= 1024;
  }

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private popupService: PopupService,
    private modalService: BsModalService,
    private walletConnectService: WalletConnectService,
    private eventService: EventService,
    private renderer: Renderer2
  ) {
    this.eventService.getLang().subscribe((val) => {
      this.lang = val || 'en';
    });
  }

  ngOnInit(): void {
    setTimeout(() => this.getBalanceData(), this.refreshRate);
    this.timeInterval = setInterval(() => this.setTimer(), 2000);
    this.subscriptions.push(this.eventService.getCounter().subscribe((val) => {
      this.liveCounter = val;
    }));
  }

  ngAfterViewInit(): void {
    const bwUrl = getParamWithoutCookie('bwUrl');
    if (bwUrl && bwUrl != '') this.showBW = true;
  }
  
  connectBestWallet() {
    window.scroll({ top: 10 });
    this.walletConnectService.connectWallet("bw");
  }

  getBalanceData() {
    const balanceData: any = this.walletConnectService.balanceData;
    this.currentStep = balanceData.currentStep;
    this.tokenSold = balanceData.totalTokensSold;
    this.totalTokens = this.currentStoreData.maxAmount || 0;
    this.ethBal = balanceData.nativeBal;
    this.usdtBal = balanceData.usdtBal;
    this.usdRaised = balanceData.usdRaised;
    this.userStaked = this.walletConnectService.stakeData.userStaked;
    this.userClaimable = balanceData.userClaimable;
    this.userEthClaimable = balanceData.ethClaimable;
    this.userBscClaimable = balanceData.bscClaimable;
    this.dataLoaded = true;

    this.getFutureToken();
    if (this.refreshId) clearTimeout(this.refreshId);
    this.refreshId = setTimeout(() => this.getBalanceData(), this.refreshRate);
  }

  connectModal(): void {
    this.modalRef = this.modalService.show(ConnectComponent, {
      class: 'modal-min modal-dialog-centered',
      backdrop: 'static'
    });
  }

  referAndEarn(): void {
    this.modalRef = this.modalService.show(ReferComponent, {
      class: 'modal-min modal-dialog-centered',
      backdrop: 'static',
      // initialState: { url: this.referUrl }
    });
  }


  get tokenLeft() {
    let num = this.currentStoreData.maxToken - this.tokenSold;
    num = +((+num).toFixed(3));
    let nf = new Intl.NumberFormat('en-US');
    nf.format(num); // "1,234,567,890"
    return nf.format(num > 0 ? num : 0);
  }

  convertNum(amount: number) {
    return convertNum(amount);
  }

  openSwap(isNative: boolean = true, isWert: boolean = false): void {
    if (this.swapComp.wertMode) {
      this.popupService.messagePopup("warning", "",
        this.translate.instant("popup.are_you_sure"),
        this.translate.instant("misc.Confirm"),
        this.translate.instant("misc.Cancel"))
        .then((result: any) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.swapComp.wertMode = false;
            this.swapMode = isNative ? 'native' : isWert ? 'card' : 'usdt';
            this.isNative = isNative;
            this.isWert = isWert;
          }
        });
    } else {
      this.swapMode = isNative ? 'native' : isWert ? 'card' : 'usdt';
      this.isNative = isNative;
      this.isWert = isWert;
    }
    setTimeout(() => this.swapComp.max(), 1000);
  }

  checkEthChain() {
    if (this.walletConnectService.isEthChain()) {
      return true;
    } else {
      this.popupService.messagePopup(
        "info",
        this.translate.instant('popup.wrong_chain', { chainName: environment.eth.chainName }),
        this.translate.instant('popup.change_network'))
        .then((res) => {
          if (res.isConfirmed) {
            this.walletConnectService.switchNetwork(environment.eth.chainIdInt);
          }
        });
      return false;
    }
  }

  

  changeChain(mode: string): void {
    if (mode == this.modeName)  return;
    if (mode == 'SOL') {
      window.location.host = "solana." + window.location.host
      return;
    } 
    let chain = mode == "ETH" ? environment.eth : environment.bsc;
    if (this.walletAddress) {
      this.walletConnectService.switchNetwork(chain.chainIdInt);
    } else {
      this.walletConnectService.chainId = chain.chainIdInt;
    }
    
  }

  getFutureToken() {
    let step = +this.currentStep;
    if (step != (this.walletConnectService.tokenomics.length - 1)) {
      this.futureToken = this.walletConnectService.tokenomics[step + 1].tokenUSDT;
    } else {
      this.futureToken = this.currentStoreData.tokenUSDT;
    }
    this.salePrice = this.currentStoreData.tokenUSDT;
  }

  get raisedPercent() {
    let percent = (this.usdRaised / this.currentStoreData.maxAmount) * 100;
    return (percent >= 100 ? 100 : percent) + '%';
  }

  get currentStoreData() {
    return this.walletConnectService.tokenomics[this.currentStep];
  }

  get walletAddress(): any {
    return this.walletConnectService.walletAddress;
  }

  get tokenPercent() {
    // let percent = (this.tokenSold / this.currentStoreData.maxToken) * 100; //for presale
    let percent = ((this.tokenSold - this.currentStoreData.minToken) / (this.currentStoreData.maxToken - this.currentStoreData.minToken)) * 100; //for presale
    return (percent >= 100 ? 100 : +(percent.toFixed(2))) + "%";
  }

  get tokenRemain() {
    return this.currentStoreData.maxToken - this.tokenSold;
  }

  get tokenName() {
    return environment.tokenName;
  }

  ngOnDestroy(): void {

    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  async claim() {
    if (this.userClaimable !== 0 || this.checkEthChain()) {
      await this.walletConnectService.claim();
      this.modalRef = this.modalService.show(ClaimComponent, {
        class: 'modal-xs modal-dialog-centered',
        backdrop: 'static',
      });
    }
  }

  get status() {
    return this.walletConnectService.swapStatus;
  }

  setTimer() {
    if (new Date() >= new Date(1719338400000)){
      this.oversale = true;
      this.eventService.setCounter(new Date(1719925200000));
    } else {
      this.eventService.setCounter(new Date(1719338400000));
    }
  }

  get modeName() {
    return this.walletConnectService.getChainData().purchaseToken;
  }

  async copy() {
    await this.popupService.messsageAlert('success', this.translate.instant("misc.successCopy"));
  }

  // get referUrl() {
  //   return this.walletConnectService.referUrl;
  // }

  wertWidget(popup: any) {
    this.modalRef = this.modalService.show(popup, {
      class: 'modal-dialog-centered',
      backdrop: 'static'
    });
  }

  yourPurchasedInfo() {
    this.popupService.messagePopup("info", this.translate.instant("popup.your_purchased_popup_info"));
  }

  changeToChain() {
    this.popupService.messagePopup("info", this.translate.instant("popup.change_chain_info"));
  }

  navigateToSection(section: string) {
    this.hashKey = section;
    if (section) {
      window.location.hash = section;
      // window.location.pathname = '/' + this.currentLanguage;
    }
  }
  stakeInfo() {
    this.popupService.messagePopup("info", this.translate.instant("staking.stake_popup_info"));
  }

  claimStake(): void {
    if (this.userClaimable == 0 || !this.checkEthChain())
      return;
    this.walletConnectService.claimStake();
    this.modalRef = this.modalService.show(ClaimComponent, {
      class: 'modal-xs modal-dialog-centered',
      backdrop: 'static',
      initialState: { isStake: true }
    });
  }

  get stakeData() {
    return this.walletConnectService.stakeData;
  }
}
