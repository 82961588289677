<div class="banner" [ngClass]="{'arabic': lang === 'ar'}" id="home">
  <div class="rightSec">

    <div class="walletBox mt-lg-4">
      <div class="px-2 px-lg-3 w-100 d-flex flex-column align-items-center justify-content-start text-center">

        <p class="font-34 text-capitalize mb-2 shadow-text" *ngIf="oversale">{{"widget.airdrop_starts" | translate }}
        </p>
        <p class="font-34 text-capitalize mb-2 shadow-text" *ngIf="!oversale">{{"widget.stage_title" | translate }}</p>


        <!-- ======= counter ======= -->

        <div class="d-flex align-items-center justify-content-center gap-2 fw-regular py-2 counter mb-2">
          <div class="rounded-3 time-card text-center d-flex" [ngClass]="{'shimmer': liveCounter._diff == 0}">
            <div class="indicator" translate>widget.days</div>
            <div class="value">{{liveCounter._days}}</div>
          </div>
          <div class="rounded-3 time-card text-center" [ngClass]="{'shimmer': liveCounter._diff == 0}">
            <div class="indicator" translate>widget.hours</div>
            <div class="value">{{liveCounter._hours}}</div>
          </div>
          <div class="rounded-3 time-card text-center" [ngClass]="{'shimmer': liveCounter._diff == 0}">
            <div class="indicator" translate>widget.minutes</div>
            <div class="value">{{liveCounter._minutes}}</div>
          </div>
          <div class="rounded-3 time-card text-center" [ngClass]="{'shimmer': liveCounter._diff == 0}">
            <div class="indicator" translate>widget.seconds</div>
            <div class="value">{{liveCounter._seconds}}</div>
          </div>
        </div>
        <p class="font-34 text-capitalize mb-2 shadow-text" *ngIf="oversale">{{"widget.last_chance_buy" | translate }}
        </p>

        <p class="text-center fs-3 mb-2" [ngClass]="{'shimmer': !usdRaised || usdRaised <=0}">1 {{tokenName}} =
          ${{salePrice}}</p>

        <!-- ======= usdt raised ======= -->

        <p class="font-25 text-center mb-2" [ngClass]="{'shimmer': !usdRaised || usdRaised <=0}">
          <span class=" text-uppercase">Over $6m+ Raised!</span>
          <!-- <span class=" text-uppercase" translate>current_raised</span>
          <span class="fw-regular ">${{convertNum(usdRaised)}}</span> -->
          <!-- <span class="fw-regular "> / ${{convertNum(currentStoreData.maxAmount)}}</span> -->
        </p>

        <!-- <div class="progress" data-percent="0" [ngClass]="{'shimmer': !usdRaised || usdRaised <=0}" *ngIf="!oversale">
          <div class="bar" [style.width]="raisedPercent"></div>
          <div class="status text-uppercase" [ngClass]="{'shimmer': !usdRaised || usdRaised <=0}">{{currentStoreData.title | translate}}</div>
        </div> -->


        <div class="d-flex justify-content-center align-items-center text-center font-24  px-3"
          [ngClass]="{'shimmer': !usdRaised || usdRaised <=0}">
          <span class=" text-uppercase">{{"widget.your_purchased" | translate :{tokenName} }}</span>
          <span class="ms-1"> = {{convertNum((userClaimable))}}</span>
          <img src="/assets/images/svg-icons/info-icon.svg" class="img-fluid ms-2 cursor-pointer"
            (click)="yourPurchasedInfo()" alt="">
        </div>

        <!-- <div class="d-flex justify-content-center align-items-center text-center font-22 px-3 mt-0" [ngClass]="{'shimmer': !usdRaised || usdRaised <=0}">
          <span class=" text-uppercase">{{"widget.your_claimable" | translate :{tokenName} }}</span>
          <span class="">&nbsp;=&nbsp;{{convertNum(userEthClaimable)}}</span>
          <img src="/assets/images/svg-icons/info-icon.svg" class="img-fluid ms-2 cursor-pointer" (click)="stakeInfo()" alt="">
        </div> -->


        <!-- ======= refer and earn ======= -->

        <!-- <button type="button" class="btn btn-sm btn-outline-secondary mb-4 mt-2 d-block refer-btn fs-6"
          (click)="referAndEarn()" *ngIf="walletAddress && referUrl != ''" translate>refer_and_earn
        </button> -->

      </div>

      <div class="px-2 px-lg-3 pb-3 mt-3 swapArea"
        [ngClass]="{'cover': status === SwapStatus.in_progess || status === SwapStatus.confirm_pending || status === SwapStatus.approval_pending}">


        <div class="tab-container gap-2">
          <button class="btn  text-uppercase d-flex align-items-center justify-content-center w-100"
            [ngClass]="{'selected': swapMode ==='native'}" (click)="openSwap(true)">
            <img src="/assets/images/svg-icons/{{modeName}}.svg" height="26" alt="" />
            <span class="ps-2 ">{{modeName}}</span>
          </button>

          <button class="btn text-uppercase d-flex align-items-center justify-content-center w-100"
            [ngClass]="{'selected': swapMode ==='usdt'}" (click)="openSwap(false)">
            <img src="/assets/images/svg-icons/usdt.svg" height="26" alt="" />
            <span class="ps-2 ">USDT</span>
          </button>

          <button class="btn text-uppercase d-flex align-items-center justify-content-center w-100"
            [ngClass]="{'selected': swapMode ==='card'}" (click)="openSwap(false, true)">
            <img src="/assets/images/svg-icons/card.svg" height="26" alt="" class="float-start" />
            <span class="ps-2 " translate>card</span>
          </button>
        </div>

        <app-swap [isNative]="isNative" [isWert]="isWert" #swap></app-swap>

        <div class="d-flex align-items-center justify-content-center gap-2 mt-4" *ngIf="!walletAddress">

          <button class="btn btn-primary w-100 text-uppercase" (click)="connectModal()" *ngIf="!showBW">
            <span translate>misc.connect_wallet</span>
          </button>

          <button class="btn btn-primary w-100 text-uppercase" (click)="connectBestWallet()" *ngIf="showBW">
            <span translate>misc.connect_bw</span>
          </button>

          <!-- <button class="btn btn-secondary w-100" (click)="changeChain(modeName === 'ETH' ? 'BNB' : 'ETH')">
            <img src="/assets/images/svg-icons/{{modeName === 'ETH' ? 'BNB' : 'ETH'}}.svg" height="18" alt=""  class="me-2" />
            <span>{{'widget.switch_to' | translate}} {{modeName === 'ETH' ? 'BNB' : 'ETH'}}</span>
          </button> -->


          <div class="btn btn-secondary w-100 buyWith btn-group position-relative" dropdown [dropup]="true"
            dropdownToggle>
            <div type="button" class="dropdown-toggle d-flex align-items-center justify-content-between w-100">
              <img src="/assets/images/svg-icons/{{modeName}}.svg" height="22" alt />
              <a class="text-decoration-none selectedMenu mx-2">{{'widget.switch_to' | translate}} {{modeName}}</a>
              <svg-icon class="dropdown" src="./assets/images/svg-icons/angle-down.svg"
                [svgStyle]="{ 'width.px':'12', 'height.px':12 }"></svg-icon>
            </div>

            <ul *dropdownMenu class="py-2 dropdown-menu dropdown-menu-right fixPos  px-3 " role="menu">
              <li class="cursor-pointer" (click)="changeChain('ETH')" *ngIf="modeName!='ETH'">
                <div type="button" class="dropdown-toggle d-flex align-items-center  w-100">
                  <img src="/assets/images/svg-icons/ETH.svg" height="22" alt />
                  <a class="text-decoration-none selectedMenu me-1">{{'widget.switch_to' | translate}} ETH</a>
                </div>
              </li>
              <li class="cursor-pointer" (click)="changeChain('BNB')" *ngIf="modeName!='BNB'">
                <div type="button" class="dropdown-toggle d-flex align-items-center  w-100">
                  <img src="/assets/images/svg-icons/BNB.svg" height="22" alt />
                  <a class="text-decoration-none selectedMenu me-2">{{'widget.switch_to' | translate}} BNB</a>
                </div>
              </li>
              <li class="cursor-pointer" (click)="changeChain('SOL')" *ngIf="modeName!='SOL'">
                <div type="button" class="dropdown-toggle d-flex align-items-center  w-100">
                  <img src="/assets/images/svg-icons/SOL.svg" height="22" alt />
                  <a class="text-decoration-none selectedMenu me-2">{{'widget.switch_to' | translate}} SOL</a>
                </div>
              </li>
            </ul>
          </div>

        </div>

        <div class="mt-3">
          <p class="font-20 text-center mb-1">
            <a *ngIf="modeName=='ETH' && walletAddress" class="text-decoration-underline cursor-pointer text-secondary"
              href="https://widget.wert.io/default/widget/?commodity=ETH%3AEthereum" target="_blank"
              translate>widget.not_enough_eth
            </a>
          </p>
          <p class="font-22 text-center mb-0 powered" translate>widget.powered_by
            <a href="https://web3paymentsolutions.io/" target="_blank">
              <img src="/assets/images/svg-icons/W3P_Black.svg" alt="" class="ms-1 poweredByIcon text-tertiary"
                style="vertical-align: middle;">
            </a>
          </p>
        </div>

      </div>
    </div>
  </div>
</div>