import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DefaultLangChangeEvent, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

import { SwapStatus, convertNum } from 'src/app/shared/constants/app-enums';
import { environment } from 'src/environments/environment';
import { EventService } from 'src/app/services/event.service';
import { PopupService } from 'src/app/services/popup.service';
import { Route, Router } from '@angular/router';
// import { SolanaConnectService } from 'src/app/services/solana-connect.service';
import { WalletConnectService } from 'src/app/services/wallet-connect.service';
import { WertComponent } from 'src/app/modals/wert/wert.component';


@Component({
  selector: 'app-swap',
  templateUrl: './swap.component.html',
  styleUrls: ['./swap.component.scss']
})

export class SwapComponent implements OnInit {

  @Input() isNative: boolean = false;
  @Input() isWert: boolean = false;
  @Input() isModal: boolean = false;
  @Output() messageEvent = new EventEmitter<string>();
  @ViewChild('swapIn') swapIn: ElementRef<HTMLInputElement> | null = null;

  private subscriptions: Subscription[] = [];
  environtment = environment;
  baseToken!: any;
  swapToken!: any;
  baseCurrencyValue: any;
  swapTokenUpdate = new Subject<any>();
  baseTokenUpdate = new Subject<any>();
  contractData: any;
  currentLang: any;
  SwapStatus = SwapStatus;
  lblockPrice = 0;
  isLowBalance = false;
  inProgress = false;

  isStake = false;
  usdtBal: number = 0;
  nativeBal: number = 0;
  minAmount = environment.minToken;

  modalRef?: BsModalRef;
  lowGas = false;
  wertMode: boolean = false;

  constructor(
    private walletConnectService: WalletConnectService,
    private http: HttpClient,
    private eventService: EventService,
    private modalService: BsModalService,
    private translate: TranslateService,
    private popupService: PopupService,
    private router: Router
  ) {

    this.currentLang = this.translate.currentLang;
    this.translate.onLangChange.subscribe((params: DefaultLangChangeEvent) => {
      this.currentLang = params.lang || 'en';
    });

    this.eventService.getNetwork().subscribe((val) => {
      this.getBal();
      if (!this.isWert) this.max();
    });

    //to check the value of swap
    this.subscriptions.push(this.swapTokenUpdate.pipe(
      debounceTime(500),
      // distinctUntilChanged()
    )
      .subscribe(value => {
        if (this.swapToken >= 0) {
          this.calculateSwap();
        } else {
          this.baseToken = 0;
        }
      }));
    this.subscriptions.push(this.baseTokenUpdate.pipe(
      debounceTime(500),
      // distinctUntilChanged()
    )
      .subscribe(value => {
        if (this.baseToken >= 0) {
          this.calculateSwapReverse();
        } else {
          this.swapToken = 0;
        }
      }));
  }

  ngOnChanges(): void {
    setTimeout(() => {
      this.swapToken = undefined;
      this.baseToken = undefined;
      this.walletConnectService.swapStatus = SwapStatus.not_started;
      this.isLowBalance = false;
      this.lowGas = false;
      this.getBal();
      this.checkLowBalance();
      if (!this.isWert) this.max();
    }, 600);
  }

  ngOnInit(): void {
    this.walletConnectService.swapStatus = SwapStatus.not_started;
    this.isLowBalance = false;
    setInterval(() => this.getBal(), 500);
  }

  async max() {
    let maxVal = this.isNative ?
      await this.walletConnectService.getDynamicAmount(this.maxTokensToBuy!*1.02041, 'getNativeAmount')
      : await this.walletConnectService.getDynamicAmount(this.maxTokensToBuy!, "getUSDTAmount");
    this.swapToken = +this.floorString((this.isNative ? (this.nativeBal - this.gasFees > 0 ? this.nativeBal - this.gasFees : 0) : this.usdtBal).toString());
    if (maxVal < this.swapToken) {
      this.swapToken = maxVal;
    }
    if (this.isWert)
      this.swapToken = 0;
    if (this.swapToken == 0) {
      this.swapToken = undefined;
      this.baseToken = 0;
    }
    this.swapTokenUpdate.next(this.swapToken)

    setTimeout(() => this.swapTokenUpdate.next(this.swapToken), 200);
  }

  getBal() {
    this.nativeBal = this.allBalance.native;
    this.usdtBal = this.allBalance.usdt;
  }

  get walletAddress(): any {
    return this.walletConnectService.walletAddress;
  }

  get status() {
  
    return this.walletConnectService.swapStatus;
  }

  get hash() {
    return this.walletConnectService.swapHash;
  }

  get tokenName() {
    return this.isNative ? this.modeName : this.isWert ? "USD" : "USDT";
  }

  get modeName() {
    return this.walletConnectService.getChainData().purchaseToken;
  }

  get chainData() {
    return this.walletConnectService.getChainData();
  }

  get gasFees() {
    return this.modeName === 'ETH' ? environment.eth.gasFees :  environment.bsc.gasFees
  }

  get allBalance() {
    return {
      native: +this.walletConnectService.balanceData.nativeBal.toFixed(10).replace(/0{0,10}$/, ""),
      usdt: this.walletConnectService.balanceData.usdtBal
    }
  }
  get maxTokensToBuy() {
    return this.walletConnectService.balanceData.maxTokensToBuy;
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  startAgain(): void {
    this.getBal();
    this.max();
    this.walletConnectService.swapStatus = SwapStatus.not_started;
  }

  confirm(isBuyStake: boolean = false) {
    this.isStake = isBuyStake;
    if (this.isWert) {
      this.modalRef = this.modalService.show(WertComponent, {
        class: 'modal-xs modal-dialog-centered transak',
        backdrop: 'static',
        initialState: { walletAddress: this.walletAddress, baseToken: this.baseToken, swapToken: this.swapToken, isStake: this.isStake }
      });
    } else {
      if (this.swapToken !== 0 && this.baseToken >= this.minAmount)
        this.createSwap(isBuyStake);
    }
  }

  calculateSwap = async () => {
    this.checkLowBalance();
    if (this.isNative) {
      this.baseToken = await this.walletConnectService.getNativeAmount(this.swapToken * 0.98);
    } else {
      this.baseToken = await this.walletConnectService.getUSDTAmount(this.swapToken);
    }
    this.baseToken = +this.baseToken.toFixed(0).replace(/0{0,0}$/, "");
    // console.log("calculateSwap here", this.isNative, this.swapToken, this.baseToken);
  }

  calculateSwapReverse = async () => {
    if (this.isNative) {
      this.swapToken = await this.walletConnectService.getDynamicAmount(this.baseToken, 'getNativeAmount');
      this.swapToken = +(this.swapToken * 1.001).toFixed(10).replace(/0{0,10}$/, "");
      console.log(this.swapToken);
    } else {
      this.swapToken = await this.walletConnectService.getDynamicAmount(this.baseToken, 'getUSDTAmount');
    }
    this.checkLowBalance();
    // console.log("calculateSwapReverse here", this.swapToken, this.baseToken);
  }

  async createSwap(isBuyStake: boolean = false) {
    if (this.inProgress)
      return
    this.inProgress = true;
    console.log('createSwap called', this.isNative, this.maxTokensToBuy)
    if (this.baseToken <= this.maxTokensToBuy!) {
      this.walletConnectService.swapStatus = SwapStatus.not_started;
      try {
        if (this.isNative) {
          if ((this.nativeBal - this.gasFees) >= this.swapToken) {
            this.isLowBalance = false;
            await this.walletConnectService.swapNativeTokens(this.baseToken, this.swapToken, isBuyStake);
          } else {
            this.isLowBalance = true;
          }
        } else {
          if (this.usdtBal >= this.swapToken && this.nativeBal >= this.gasFees) {
            this.isLowBalance = false;
            await this.walletConnectService.swapCryptoForUSDT(this.baseToken, this.swapToken, false, isBuyStake);
          } else {
            this.isLowBalance = true;
            if (this.nativeBal < this.gasFees)
              this.lowGas = true;
          }
        }
        this.inProgress = false;
      }
      catch (err: any) {
        console.log('createSwap', err);
        if (err && err.code == 4001) {
          this.walletConnectService.swapStatus = SwapStatus.rejected;
        } else {
          this.walletConnectService.swapStatus = SwapStatus.failed;
        }
        this.inProgress = false;
      }
    } else {
      this.popupService.messagePopup("warning", 
      this.translate.instant('widget.max_token_exceed', { maxTokensToBuy: this.maxTokensToBuy }), this.translate.instant('widget.max_token_title'));
      this.walletConnectService.swapStatus = SwapStatus.not_started;
      this.inProgress = false;
    }
  }


  viewUrl() {
    const blkUrl = this.chainData.blockExplorerURL;
    const urlWindow = environment.production ? blkUrl + this.hash :  blkUrl + this.hash + '?cluster=devnet';
    window.open( urlWindow, '_blank');
  }

  get cryptoName() {
    return environment.tokenName;

  }

  get stakeData() {
    return this.walletConnectService.stakeData;
  }


  checkLowBalance(): void {
    if (this.isNative) {
      if ((this.nativeBal - this.gasFees) >= this.swapToken) {
        this.isLowBalance = false;
      } else {
        this.isLowBalance = true;
      }
    } else {
      if (+this.usdtBal >= +this.swapToken && +this.nativeBal >= this.gasFees) {
        this.isLowBalance = false;
        this.lowGas = false;
      } else {
        this.isLowBalance = true;
        if (this.nativeBal < this.gasFees)
          this.lowGas = true;
      }
    }
  }

  openWert(): void {
    this.eventService.broadcastEvent("OPEN_WERT", true);
  }


  checkChain(): boolean {
    return true;
    // if (this.walletConnectService.chainId == this.chainData.chainIdInt) {
    //   return true;
    // } else {
    //   if (this.walletConnectService.mode == "wallet") {
    //     this.walletConnectService.switchNetwork(this.chainData.chainIdInt).then(() => {
    //       return true;
    //     })
    //     return true;
    //   } else {
    //     this.popupService.messagePopup("info",
    //       this.translate.instant('popup.wrong_chain', { chainName: this.chainData.chainName }),
    //       this.translate.instant('popup.change_network'))
    //       .then((res) => {
    //         if (res.isConfirmed) {
    //           this.walletConnectService.switchNetwork(this.chainData.chainIdInt);
    //         }
    //       });
    //     return false;
    //   }
    // }
  }

  convertNum(amount: number, decimals: number = 2) {
    return convertNum(amount, decimals);
  }

  floorString(str: string) {
    let pos = str.indexOf('.');
    return (pos >= 0) ? ((str + '00').slice(0, pos + 6)) : (str + '.00');
  }

  

  changeChain(mode: string): void {
    if (mode == this.modeName) return;
    if (mode == 'SOL') {
      window.location.host = "solana." + window.location.host
      return;
    }
    
    let chain = mode == "ETH" ? environment.eth : environment.bsc;
    if (this.walletAddress) {
      this.walletConnectService.switchNetwork(chain.chainIdInt);
    } else {
      this.walletConnectService.chainId = chain.chainIdInt;
    }
  }

  changeTab(isNative: boolean = true): void {
    this.isNative = isNative;
    setTimeout(() => this.max(), 1000);
  }

  stakeNow(): void {
    this.walletConnectService.switchNetwork(environment.eth.chainIdInt);
    this.router.navigateByUrl(this.currentLang + '/staking', {});
  }
}
