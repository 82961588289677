<div class="modal-header">
  <h4 class="modal-title text-center" translate>ref_link</h4>
  <!-- <svg-icon src="./assets/images/svg-icons/close.svg" [svgStyle]="{ 'width.px':14, 'height.px':14 }" class="pull-right text-white cursor-pointer" (click)="modalRef.hide()"></svg-icon> -->
</div>
<div class="modal-body">

  <div class="fw-medium font-15 text-center" translate>ref_text</div>

  <button type="button" class="btn btn-sm btn-outline-primary my-3 d-block refer-btn wx-250 mx-auto" (click)="copy()" ngxClipboard [cbContent]="url">
    <div class="d-flex align-items-center justify-content-center">
      <span class="d-inline-block ellipsis font-13 text-primary">{{url}}</span>
      <svg-icon src="./assets/images/svg-icons/copy.svg" [svgStyle]="{ 'width.px':20, 'height.px':20 }" class=""></svg-icon>
    </div>
  </button>

  <div class="footer">
    <button type="button" class="btn btn-sm btn-primary w-100 mt-3 icon-btn d-block" (click)="modalRef.hide()" translate>close</button>
  </div>

</div>
