import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TooltipModule  } from 'ngx-bootstrap/tooltip';


import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { LanguageComponent } from './components/language/language.component';
import { ConnectComponent } from './modals/connect/connect.component';
import { ReferComponent } from './modals/refer/refer.component';
import { SwapComponent } from './components/swap/swap.component';
import { ClaimComponent } from './modals/claim/claim.component';
import { WertComponent } from './modals/wert/wert.component';
import { AboutComponent } from './components/about/about.component';
import { HowToBuyComponent } from './components/how-to-buy/how-to-buy.component';
import { BuyWidgetComponent } from './components/buy-widget/buy-widget.component';


@NgModule({
  declarations: [
    AppComponent,
    LanguageComponent,
    HeaderComponent,
    HomeComponent,
    SwapComponent,
    ConnectComponent,
    ReferComponent,
    ClaimComponent,
    WertComponent,
    AboutComponent,
    HowToBuyComponent,
    BuyWidgetComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    TooltipModule.forRoot(),
    SharedModule,
    HttpClientModule,
    NgxChartsModule,
    CarouselModule,
    AccordionModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
