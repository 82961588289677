
<div class="modal-header">
  <h4 class="modal-title text-center">{{(isStake ? 'staking.claim_stake_title' : (isWithdraw ? 'staking.withdraw_title' :  'claim_title')) | translate}}</h4>
</div>

<div class="modal-body text-center" *ngIf="status != SwapStatus.complete">
  <p class="text-white text-center mb-3 font-sm-15">{{(isStake ? 'staking.claim_stale_help' : 'claim_help') | translate}}</p>
  <div class="footer">
    <button type="button" class="btn btn-secondary fs-7 w-50" (click)="modalRef.hide()" translate>misc.ok</button>
  </div>
</div>

<div class="modal-body text-center" *ngIf="status == SwapStatus.complete">
  <img src="./assets/images/popup-icons/success.svg" alt="complete" class="statusIcon">
  <div class="text-secondary text-center mb-3 font-sm-15" translate>staking.stake_claim_success</div>
  <a type="button" href="{{lang}}/staking" class="btn btn-secondary fs-7 w-auto" (click)="modalRef.hide()" translate>staking.dashboard</a>
</div>
