<!-- -------- desktop  header --------- -->
<div class="header desktop d-flex justify-content-between align-items-center" [ngClass]="{'scrolled': isScrolled}"
  *ngIf="windowSize > 1024">
  <div class="w-100">
    <div class="header-wrap d-flex justify-content-between align-items-center w-100">
      <div class="cursor-pointer" (click)="navigateToSection('home'); addActive($event)" #navigation>
        <svg-icon src="./assets/images/svg-icons/logo.svg" [svgStyle]="{ 'width.px':'170', 'height.px':33 }"></svg-icon>
      </div>

      <div class="d-flex justify-content-center align-items-center ">
        <a href="javascript:void(0)" class="text-dark mx-3 px-3 text-center"
          (click)="navigateToSection('about'); addActive($event)" title="{{'about'}}" translate>about</a>
        <a href="javascript:void(0)" class="text-dark mx-3 px-3 text-center"
          (click)="navigateToSection('how_to'); addActive($event)" title="{{'how_to'}}" translate>how_to</a>
      </div>


      <div class="d-flex align-items-center">
        <div class="btn btn-primary text-captlized" (click)="showWidget('home')" *ngIf="!walletAddress" translate>
          widget.buy_now</div>
        <div class="btn btn-primary text-captlized" (click)="disconnect()" *ngIf="walletAddress">{{walletAddress |
          trimAddress}}</div>

        <div class="btn-group language ms-3" dropdown>
          <div dropdownToggle type="button" class="dropdown-toggle amountType d-flex align-items-center">
            <img class="flag-icon" src="./assets/images/flags/{{selectedLanguage?.flag}}.svg" alt="">
            <span class="ms-1 font-18 text-uppercase fw-bold">{{ selectedLanguage?.value }}</span>
            <svg-icon class="dropdown" src="./assets/images/svg-icons/angle-down.svg"></svg-icon>
          </div>

          <ul *dropdownMenu class="dropdown-menu dropdown-menu-right bg-white" role="menu">
            <li class="p-2 cursor-pointer" role="menuitem" *ngFor="let lang of languagesList"
              (click)="changeLanguage(lang.value)">
              <img src="./assets/images/flags/{{lang.flag}}.svg" alt="">
              <span class="text-dark fw-bold">{{ lang.name }}</span>
            </li>
          </ul>
        </div>

        <div class="social-icons d-flex align-items-center ">
          <a href="https://twitter.com/Sealana_Token" target="_blank" class="mx-2">
            <img src="/assets/images/svg-icons/x.png" alt="">
          </a>
          <a href="https://t.me/Sealana_Token" target="_blank" class="mx-2">
            <img src="/assets/images/svg-icons/telegram.svg" alt="">
          </a>
        </div>

      </div>
    </div>
  </div>
</div>


<!-- -------- mobile header --------- -->

<div class="header mobile" [ngClass]="{'scrolled': isScrolled}" *ngIf="windowSize <= 1024">

  <div class="header-wrap w-100 d-flex justify-content-be align-items-center">
    <div class="header-wrap w-100 d-flex justify-content-between align-items-center">
      <div class="cursor-pointer flex-grow-1" (click)="navigateToSection('home'); addActive($event)" #navigation>
        <svg-icon class="mobile-logo" src="./assets/images/svg-icons/logo.svg"
          [svgStyle]="{ 'max-width.px':130, 'height.px': 33 }"></svg-icon>
      </div>
      <div class="me-2" (click)="openCloseMenu()">
        <svg-icon src="./assets/images/svg-icons/{{showMenu ? 'burger-close' : 'burger-icon'}}.svg"
          [svgStyle]="{ 'width.px':32, 'height.px':32 }"></svg-icon>
      </div>
    </div>

    <div class="menu-overlay" *ngIf="showMenu">
      <div class="sub-menu d-flex flex-column justify-content-start align-items-center pull-left"
        [ngClass]="{'open': openMenu}">
        <div class="d-flex flex-column justify-content-start align-items-center h-100">
          <a href="javascript:void(0)" class="text-dark text-center"
            (click)="navigateToSection('about'); addActive($event); showMenu=false; openMenu=false" title="{{'about'}}"
            translate>about</a>
          <a href="javascript:void(0)" class="text-dark text-center"
            (click)="navigateToSection('how_to'); addActive($event); showMenu=false; openMenu=false"
            title="{{'how_to'}}" translate>how_to</a>
          <a href="javascript:void(0)" class="text-dark text-center"
            (click)="disconnect(); showMenu=false; openMenu=false" *ngIf="walletAddress" title="{{'how_to'}}"
            translate>{{walletAddress |
            trimAddress}}</a>
          <div class="btn-group language mx-2 mt-3" dropdown>
            <div dropdownToggle type="button" class="dropdown-toggle amountType d-flex align-items-center">
              <img class="flag-icon" src="./assets/images/flags/{{selectedLanguage?.flag}}.svg" alt="">
              <span class="ms-1 font-14 text-uppercase fw-bold">{{ selectedLanguage?.value }}</span>
              <svg-icon class="dropdown" src="./assets/images/svg-icons/angle-down.svg"></svg-icon>
            </div>
            <ul *dropdownMenu class="dropdown-menu bg-white" role="menu">
              <li class="p-2 cursor-pointer" role="menuitem" *ngFor="let lang of languagesList"
                (click)="changeLanguage(lang.value)">
                <img src="./assets/images/flags/{{lang.flag}}.svg" alt="">
                <span class="text-dark fw-bold">{{ lang.name }}</span>
              </li>
            </ul>
          </div>
          <div class="social-icons d-flex align-items-center mt-3">
            <a href="https://twitter.com/Sealana_Token" target="_blank" class="mx-1 border-0">
              <img src="/assets/images/svg-icons/x.png" alt="">
            </a>
            <a href="https://t.me/Sealana_Token" target="_blank" class="mx-1 border-0">
              <img src="/assets/images/svg-icons/telegram.svg" alt="">
            </a>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>