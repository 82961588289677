export const environment = {
  production: true,
  eth: {
    chainId: '0x1', //hexadecimal for metamask
    chainIdInt: 1,
    chainName: 'Ethereum Mainnet',
    gasFees: 0.015,
    purchaseToken: 'ETH',
    hasStaking: true,
    nativeFunction: "buyWithEth",
    presaleAddress: '0x0B814dFf67adCE61dA507a5812a5309b7aDb9519',
    usdTAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    rpcURL: 'https://black-old-owl.quiknode.pro/07bc4e719e15e678a8a859a906858aa08beed05f/',
    backupRPC: 'https://black-old-owl.quiknode.pro/07bc4e719e15e678a8a859a906858aa08beed05f/',
    blockExplorerURL: 'https://etherscan.io/tx/',
    chainInfo: {
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: '0x1',
          chainName: 'Ethereum Mainnet',
          nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
          },
          rpcUrls: ['https://rpc.ankr.com/eth'],
          blockExplorerUrls: ['https://etherscan.io/'],
        },
      ],
    },
  },

  bsc: {
    chainId: "0x38", //hexadecimal for metamask
    chainIdInt: 56,
    chainName: "BSC Mainnet",
    gasFees: 0.0025,
    purchaseToken: "BNB",
    hasStaking: false,
    nativeFunction: "buyWithBNB",
    presaleAddress: "0x0B814dFf67adCE61dA507a5812a5309b7aDb9519",
    usdTAddress: "0x55d398326f99059fF775485246999027B3197955",
    rpcURL: "https://weathered-quick-panorama.bsc.quiknode.pro/fb0efe91ce8a7bccaaa68a98a211980bc48c89f0/",
    backupRPC: "https://weathered-quick-panorama.bsc.quiknode.pro/fb0efe91ce8a7bccaaa68a98a211980bc48c89f0/",
    blockExplorerURL: "https://bscscan.com/tx/",
    chainInfo: {
      method: 'wallet_addEthereumChain',
      params: [{
        chainId: "0x38",
        chainName: "BSC Mainnet",
        nativeCurrency: {
          name: "BNB",
          symbol: "BNB",
          decimals: 18,
        },
        rpcUrls: ["https://bsc-dataseed2.ninicoin.io/"],
        blockExplorerUrls: ["https://bscscan.com/"],
      }]
    }
  },
  solana: {
    rpcUrl: 'https://morning-wild-mansion.solana-mainnet.quiknode.pro/e2a82489fd9ac3e7d856b789502ab6db3696b839/',
    blockExplorerURL: 'https://solscan.io/tx/',
    usdcTokenAddress: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    presaleAddress: 'GB9N4HHYHnkdX4usu61JyzyXqCRn2tzXeFopHrd6isqK',
    directAddress: '3LeVizuW3YoCnjfMfuQ22rSFQLDLdo9jXLKjyqfBU3w5'
  },
  
  minToken:         10,
  tokenAddress:     '0x0000000000000000000',
  stakingAddress:   '0x0000000000000000000',
  tokenName:        '$SEAL',
  apyCalcConst:      0,
  iidDashFx:         1668,
  urlDashFx:         'https://m75rs6ploizbuuwhp4mlvys63e0xswbo.lambda-url.eu-west-2.on.aws/',
  referUrl:          '', 
  wertOrigin:       'https://widget.wert.io',
  partnerId:        '01HXH45F82BY0EE7Z848G4T7N3',
  wertUrl:          'https://wpwbnylbjtqmwj5zvbm4ev5rse0leype.lambda-url.eu-west-2.on.aws/',
  walletConnectId:  'cd8c5fb1836d8f7529e5bc4e6df31df5',
  bwUniversalLink:  'https://best-wallet-staging.web.app',
  bwDeepLink:       'bw-staging://app/connect?',
  bwApiUrl:         'https://stage-api.bestwallet.com/presale-transactions',
  showBestWallet:   true,
};
