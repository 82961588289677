import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EventService } from 'src/app/services/event.service';
import { WalletConnectService } from 'src/app/services/wallet-connect.service';
import { SwapStatus } from 'src/app/shared/constants/app-enums';

@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.scss']
})
export class ClaimComponent {
  SwapStatus = SwapStatus;
  isStake = false;
  lang = 'en';
  isWithdraw = false;

  constructor(
    private eventService: EventService,
    public modalRef: BsModalRef,
    private walletConnectService: WalletConnectService,
  ) { 
    this.eventService.getLang().subscribe((val) => {
      this.lang = val || 'en';
    });
  }

  get status() {
    return this.walletConnectService.swapStatus;
  }
}
