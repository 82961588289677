import { ApplicationRef, Injectable } from "@angular/core";
import { configureChains, connect, fetchBalance, prepareWriteContract, readContracts, switchNetwork, watchAccount, watchNetwork, writeContract, disconnect, waitForTransaction, createConfig, mainnet } from '@wagmi/core'
import { HttpClient } from '@angular/common/http';
import { publicProvider } from '@wagmi/core/providers/public';
import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc';
import { MetaMaskConnector } from '@wagmi/core/connectors/metaMask';
import { WalletConnectConnector } from '@wagmi/core/connectors/walletConnect';
import { CoinbaseWalletConnector } from '@wagmi/core/connectors/coinbaseWallet';
import { TranslateService } from '@ngx-translate/core';
import { createPublicClient, encodeFunctionData, formatEther, http, parseEther } from 'viem';
import { bsc, bscTestnet, polygon, polygonMumbai, sepolia } from 'viem/chains';

import { PopupService } from './popup.service';
import { EventService } from './event.service';
import { SwapStatus, getBrowserName, getPremiumTokenRef, getParam, getParamWithoutCookie } from '../shared/constants/app-enums';
import { environment } from 'src/environments/environment';
import { ConfirmOptions, Connection, PublicKey } from "@solana/web3.js";
import { Program, AnchorProvider, BN } from "@coral-xyz/anchor";

/**
 * variable used for the presale smart contract
 * of different network
 */

const presaleEth = require('src/assets/contracts/presaleETH.json');
const eth_usdt_token = require('src/assets/contracts/eth_usdt_token.json');
const presaleBsc = require('src/assets/contracts/presaleBSC.json');
const bsc_usdt_token = require('src/assets/contracts/bsc_usdt_token.json');
// const presalePoly = require('src/assets/contracts/presalePOLY.json');
// const poly_usdt_token = require('src/assets/contracts/poly_usdt_token.json');
const presaleSol = require("src/assets/contracts/presale_Solana.json");
const staking = require('src/assets/contracts/staking.json');
const token = require('src/assets/contracts/token.json');

/**
 * here the presale ABI and Address are set
 * for read, write and prepair contract
 */


const stakeContract = {
  address: environment.stakingAddress as any,
  abi: staking.abi,
}

const presaleContractEth = {
  address: environment.eth.presaleAddress as any,
  abi: presaleEth.abi,
}

const ethUsdtContract = {
  address: environment.eth.usdTAddress as any,
  abi: eth_usdt_token.abi,
}


const presaleContractBsc = {
  address: environment.bsc.presaleAddress as any,
  abi: presaleBsc.abi,
}

const bscUsdtContract = {
  address: environment.bsc.usdTAddress as any,
  abi: bsc_usdt_token.abi,
}

// const presaleContractPoly = {
//   address: environment.poly.presaleAddress as any,
//   abi: presalePoly.abi,
// }

// const polyUsdtContract = {
//   address: environment.poly.usdTAddress as any,
//   abi: poly_usdt_token.abi,
// }

const tokenContract = {
  address: environment.tokenAddress as any,
  abi: token.abi,
}


@Injectable({
  providedIn: 'root',
})
export class WalletConnectService {
  swapStatus = SwapStatus.not_started;
  walletAddress: string = '';
  referUrl: string = '';
  client: any;
  wagmiData: any;
  chainId: any;
  mode: any;
  presaleData: any;
  swapHash: any = '';
  refreshId: any;
  refreshRate: number = 5000;

  /* variables used for Wallet connection */

  metaMaskConnector: any;
  walletConnector: any;
  walletConnectorBW: any;
  uriConnector: any;
  coinbaseConnector: any;
  isBW = false;

  /* predefined values of the essential variables */

  balanceData: {
    nativeBal: number,
    usdtBal: number,
    ethTokenSold: number,
    bscTokenSold: number,
    // polyTokenSold: number,
    totalTokensSold: number,
    ethClaimable?: number,
    // polyClaimable?: number,
    bscClaimable?: number,
    userClaimable: number,
    currentStep: number,
    startTime?: any,
    endTime?: any,
    paused?: boolean,
    usdRaised: number,
    usdRaisedSol: number,
    usdRaisedSolDirect: number,
    maxTokensToBuy?: number,
    oneEth: number,
    oneBnb: number,
    // onePoly: number,
    ethUsdt: number,
    bnbUsdt: number,
    // polyUsdt: number
  } = {
      nativeBal: 0,
      usdtBal: 0,
      ethTokenSold: 0,
      bscTokenSold: 0,
      totalTokensSold: 0,
      ethClaimable: 0,
      bscClaimable: 0,
      userClaimable: 0,
      // polyTokenSold: 0,
      // polyClaimable: 0,
      startTime: '',
      endTime: '',
      paused: false,
      currentStep: 0,
      usdRaised: 0,
      usdRaisedSol: 0,
      usdRaisedSolDirect: 0,
      maxTokensToBuy: 0,
      oneEth: 0,
      oneBnb: 0,
      ethUsdt: 0,
      bnbUsdt: 0,
      // polyUsdt: 0,
      // onePoly: 0,
    }


  stakeData: {
    userStaked: number;
    totalStaked: number;
    rewardPerRound: number;
    userPoolPercent: number;
    apy: number;
    unlockTime: number;
    userReward: number;
    userBal: number;
    isClaimEnabled: boolean;
    userCount: number;
    tokenPrice: number;
    planId: number;
    stackPercent: number;
    rewardsPA: number;
    rewardsPaid: number;
  } = {
      userStaked: 0,
      totalStaked: 0,
      rewardPerRound: 0,
      userPoolPercent: 0,
      apy: 0,
      unlockTime: 0,
      userReward: 0,
      userBal: 0,
      isClaimEnabled: false,
      userCount: 0,
      tokenPrice: 0,
      planId: 0,
      stackPercent: 0,
      rewardsPA: 0,
      rewardsPaid: 0,
    };

  /**
   * Tokenomics data is defining the no of preslae stages,
   * price of each token, minimum and maximum no of token
   * for each stages. It is also defining the end date of each presale stages.
   */
  tokenomics = [
    {
      minToken: 0,
      maxToken: 1000000000,      //1
      maxAmount: 22000000,
      tokenUSDT: 0.022,
      title: 'widget.round_title',
      endDate: 1706954399,
    }
  ];

  tokenomics2: any;
  viemClient: any;
  private stateInfoAddress: any;
  private program: any;
  private provider: any;
  private connection: Connection;
  curWalletName: string = '';
  programId = new PublicKey(environment.solana.presaleAddress);
  price = 150;
  constructor(
    private http: HttpClient,
    private appRef: ApplicationRef,
    private eventService: EventService,
    private popupService: PopupService,
    private transletService: TranslateService,

  ) {

    /**
     * Wagmi Core chain, provider and connector configuration.
     * If production mode then mainnet and paid RPC provider else
     * testnet and publicProvider
     */
    //Solana stuff
    this.http.get('https://api.diadata.org/v1/assetQuotation/Solana/0x0000000000000000000000000000000000000000')
      .subscribe((res: any) => {
        console.log(res.Price);
        this.price = res.Price;
      })
    this.connection = new Connection(environment.solana.rpcUrl, "processed");
    this.provider = new AnchorProvider(this.connection, this.getWindowObject(), "processed" as ConfirmOptions);
    this.setProgram();
    let tempVar = JSON.stringify(this.tokenomics);
    this.tokenomics2 = JSON.parse(tempVar);
    const currentChain: any = environment.production ? [mainnet, bsc] : [sepolia, bscTestnet];
    const { chains, publicClient, webSocketPublicClient } = configureChains(
      currentChain,
      environment.production ?
        [
          jsonRpcProvider({
            rpc: (chain) => ({
              http: chain.id === environment.eth.chainIdInt ? environment.eth.rpcURL : environment.bsc.rpcURL,
            }),
          }),
          jsonRpcProvider({
            rpc: (chain) => ({
              http: chain.id === environment.eth.chainIdInt ? environment.eth.backupRPC : environment.bsc.backupRPC,
            }),
          }),
        ]
        : [publicProvider()]
    );

    /**
     * Setup of Metamask connector which will work
     * with metamask browser extension
     */

    this.metaMaskConnector = new MetaMaskConnector({
      chains: chains,
    });

    /**
     * Setup of wallet connector v2 which will work
     * with mobile app like trustwallet, alphawallet app.
     * the projectId is mandatory and can be generated from
     * https://cloud.walletconnect.com/sign-in
     */

    this.walletConnector = new WalletConnectConnector({
      chains,
      options: {
        projectId: environment.walletConnectId,
        qrModalOptions: {
          explorerRecommendedWalletIds: ['fe68cea63541aa53ce020de7398968566dfe8f3725663a564cac89490247ed49', 'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0']
        }
      },
    });

    this.walletConnectorBW = new WalletConnectConnector({
      chains,
      options: {
        projectId: environment.walletConnectId,
        qrModalOptions: {
          explorerRecommendedWalletIds: ['fe68cea63541aa53ce020de7398968566dfe8f3725663a564cac89490247ed49']
        }
      },
    })

    this.coinbaseConnector = new CoinbaseWalletConnector({
      chains,
      options: {
        appName: '$SEAL',
        appLogoUrl: 'https://sealana.io/assets/images/svg-icons/logo.svg',
      }
    })

    this.uriConnector = new WalletConnectConnector({
      chains,
      options: {
        showQrModal: false,
        projectId: environment.walletConnectId,
        qrModalOptions: {
          explorerRecommendedWalletIds: ['fe68cea63541aa53ce020de7398968566dfe8f3725663a564cac89490247ed49']
        }
      },
    });

    this.uriConnector.addListener('message', (uri: any) => {
      if (uri.type === 'display_uri') {
        const userAgent = window.navigator.userAgent.toLowerCase(),
          ios = /iphone|ipod|ipad/.test(userAgent);
        const bwUrl = getParamWithoutCookie('bwUrl');
        console.log("bwUrl=========>", bwUrl);
        let urlParams = uri.data + "&callbackUrl=" + window.location.href + "&browser=" + getBrowserName();
        console.log("url params=========>", urlParams);
        console.log("wondow log params=========>", window.location.href);
        if (ios) {
          const link = document.createElement('a');
          if (bwUrl && bwUrl != '') {
            link.href = bwUrl + urlParams;
            link.click();
            this.isBW = true;
          } else {
            // link.href = environment.bwUniversalLink + '/connect?' + urlParams;
            link.href = environment.bwDeepLink + urlParams;
            // link.target = '_blank';
            link.click();
            this.isBW = false;
          }
        } else {
          if (bwUrl && bwUrl != '') {
            const link = document.createElement('a');
            link.href = bwUrl + urlParams;
            link.click();
            this.isBW = true;
          } else {
            const link = document.createElement('a');
            // link.href = environment.bwUniversalLink + "/connect?" + urlParams;
            link.href = environment.bwDeepLink + urlParams;
            // link.target = '_blank';
            link.click();
            this.isBW = false;
          }
        }
      }
    });

    /**
     * wagmi createConfig setup for auto connect feature
     * and available connectors of wagmi.
     */

    this.client = createConfig({
      autoConnect: true,
      connectors: [this.metaMaskConnector, this.walletConnector, this.walletConnectorBW, this.uriConnector, this.coinbaseConnector],
      publicClient,
      webSocketPublicClient,
    });

    /**
     * watchNetwork function continuously monitors the userâ€™s
     * connected network. Whenever user switched/changed its
     * current network, this function keeps tracking that data
     * and updates the current chain id accordingly.
     */

    watchNetwork((network: any) => {
      this.chainId = network?.chain?.id;
      this.getPresalesData();
      setTimeout(() => this.eventService.setNetwork(), 2000);
      this.appRef.tick();
      // console.log('watchNetwork =', network);
    });

    /**
     * This function continuously monitors the userâ€™s connected
     * wallet account. Whenever user switched/changed its current account,
     * this function updates the current wallet address accordingly.
     */
    watchAccount((account: any) => this.setData(account));
  }

  /**
   * this is connect wallet function. The mode argument
   * is actually providing a choice to the app user whether
   * they want to connect via Metamask, WalletConnect V2 or web3Auth.
   * This function is mainly called from connect modal component.
   */

  async connectWallet(mode: string, isBsNetwork: boolean = false) {
    console.log('connectWallet called', mode)
    this.mode = mode;
    this.windowDataLayer('connectWallet', 'start', 1, 1, 0, '', '', 0, 0);
    await connect({
      connector: mode === 'metamask' ? this.metaMaskConnector
        : mode === 'wallet' ? this.walletConnector
          : mode === 'walletBW' ? this.walletConnectorBW
            : mode === 'coinbase' ? this.coinbaseConnector
              : mode === 'bw' ? this.uriConnector
                : this.walletConnector,
      chainId: isBsNetwork ? environment.bsc.chainIdInt : environment.eth.chainIdInt
    }).then(
      (success: any) => {
        console.log('\n==== wallet connected ====\n', this.client, '\n====');
        setTimeout(() => {
          this.sendDashFx('', 0, 0, true);
          this.windowDataLayer('connectWallet', 'successful', 2, 1, 0, '', '', 0, 0);
        }, 2000);
      },
      (err: any) => {
        console.log('\n==== wallet connection issue ====\n', err, '\n====');
      }
    );
  }

  /**
   * this is disconnect wallet function. After a successful disconnection
   * a developer can reset the different variables values.
   */

  async disConnectWallet() {
    await disconnect().then(
      (success: any) => {
        console.log('\n==== wallet disconnected ====\n', this.client);
        localStorage.clear();
        this.chainId = null;
        this.walletAddress = '';
        this.referUrl = '';
        this.balanceData.nativeBal = 0;
        this.balanceData.usdtBal = 0;
        this.eventService.setNetwork();
        this.eventService.isConnected = false;
        this.getPresalesData();
      },
      (err: any) => {
        console.log('\n==== wallet disconnect issue ====\n');
        console.log('disconnect issue =', err);
      }
    );
  }

  /**
   * This function is getting called from watchAAccount.
   * Here we are saving the wagmi wallet address and
   * also calling the getPresale function for re-read
   * the smart contract function with new wallet address
   */

  setData(account: any): void {
    this.wagmiData = account;
    this.walletAddress = this.wagmiData.address?.toLowerCase();
    this.refreshRate = account.address ? 5000 : 50000;
    this.eventService.isConnected = this.wagmiData?.isConnected;
    this.appRef.tick();
    this.getPresalesData();
    this.viemClient = createPublicClient({
      chain: mainnet,
      transport: http()
    })
  }

  /**
   * In thiss fnctiion we are calling the different method
   * of smart contract to get different values
   */

  async getPresalesData() {
    this.getPresaleDataSol();
    // if (this.walletAddress && this.referUrl == '') this.refer();
    const config = {
      contracts: [

        // ===== ETH contract =========
        {
          ...presaleContractEth,
          functionName: 'totalTokensSold', // this method is returning the no of tokens sold via BSC
          chainId: environment.eth.chainIdInt, // 0
          args: [],
        },
        {
          ...presaleContractEth,
          functionName: 'ethBuyHelper', // this method is returning the amount of bnb required for 1 app token.
          chainId: environment.eth.chainIdInt, // 1
          args: [1],
        },
        {
          ...presaleContractEth,
          functionName: 'usdtBuyHelper', // this method is returning the amount of usdt required for 1 app token.
          chainId: environment.eth.chainIdInt, // 2
          args: [1],
        },
        {
          ...presaleContractEth,
          functionName: 'usdRaised', // this method is returning the total USDT raised via BSC token.
          chainId: environment.eth.chainIdInt, // 3
          args: [],
        },
        {
          ...presaleContractEth,                 // 4 MAx token allowed to buy
          functionName: 'maxTokensToBuy',
          chainId: environment.eth.chainIdInt,
          args: [],
        },
        {
          ...presaleContractEth,
          functionName: 'currentStep',
          chainId: environment.eth.chainIdInt,    // 5
          args: [],
        },
        {
          ...presaleContractEth,
          functionName: 'roundDetails',
          chainId: environment.eth.chainIdInt,    // 6
          args: [2],
        },
        {
          ...presaleContractEth,                    // 7
          functionName: 'trackRemainingTokens',
          chainId: environment.eth.chainIdInt,
          args: [],
        },
        {
          ...presaleContractEth,
          functionName: 'userDeposits', // this method is returning the total token purchased by current user via ETH network.
          chainId: environment.eth.chainIdInt,      // 8
          args: [
            this.walletAddress || '0x0000000000000000000000000000000000000000',
          ],
        },
        {
          ...presaleContractEth,
          functionName: 'paused',                 // this method is returning the pause status.
          chainId: environment.eth.chainIdInt,    // 9
          args: [],
        },


        // =========== BSC Contract ==============

        {
          ...presaleContractBsc,
          functionName: 'totalTokensSold',        // this method is returning the no of tokens sold via BSC
          chainId: environment.bsc.chainIdInt,    // 10 
          args: []
        },
        {
          ...presaleContractBsc,
          functionName: 'bnbBuyHelper',           // this method is returning the amount of bnb required for 1 app token.
          chainId: environment.bsc.chainIdInt,    // 11 
          args: [1]
        },
        {
          ...presaleContractBsc,
          functionName: 'usdtBuyHelper',          // this method is returning the amount of usdt required for 1 app token.
          chainId: environment.bsc.chainIdInt,    // 12 
          args: [1]
        },
        {
          ...presaleContractBsc,
          functionName: 'usdRaised',              // this method is returning the total USDT raised via BSC token.
          chainId: environment.bsc.chainIdInt,    // 13 
          args: []
        },
        {
          ...presaleContractBsc,                  // 14 
          functionName: 'trackRemainingTokens',
          chainId: environment.bsc.chainIdInt,
          args: []
        },
        {
          ...presaleContractBsc,
          functionName: 'userDeposits',           // this method is returning the total token purchased by current user via BSC network.
          chainId: environment.bsc.chainIdInt,    // 15  
          args: [this.walletAddress || '0x0000000000000000000000000000000000000000'],
        },

        // =========== POLY Contract ==============

        // {
        //   ...presaleContractPoly,
        //   functionName: 'totalTokensSold',        // this method is returning the no of tokens sold via BSC
        //   chainId: environment.poly.chainIdInt,    // 16 
        //   args: []
        // },
        // {
        //   ...presaleContractPoly,
        //   functionName: 'maticBuyHelper',           // this method is returning the amount of bnb required for 1 app token.
        //   chainId: environment.poly.chainIdInt,    // 17 
        //   args: [1]
        // },
        // {
        //   ...presaleContractPoly,
        //   functionName: 'usdtBuyHelper',          // this method is returning the amount of usdt required for 1 app token.
        //   chainId: environment.poly.chainIdInt,    // 18 
        //   args: [1]
        // },
        // {
        //   ...presaleContractPoly,
        //   functionName: 'usdRaised',              // this method is returning the total USDT raised via BSC token.
        //   chainId: environment.poly.chainIdInt,    // 19 
        //   args: []
        // },
        // {
        //   ...presaleContractPoly,                  // 20 
        //   functionName: 'trackRemainingTokens',
        //   chainId: environment.poly.chainIdInt,
        //   args: []
        // },
        // {
        //   ...presaleContractPoly,
        //   functionName: 'userDeposits',           // this method is returning the total token purchased by current user via BSC network.
        //   chainId: environment.poly.chainIdInt,    // 21  
        //   args: [this.walletAddress || '0x0000000000000000000000000000000000000000'],
        // },


        // -------------- staking part ------------
        // {
        //   ...stakeContract,
        //   functionName: 'poolStakers',
        //   chainId: environment.eth.chainIdInt, // 16
        //   args: [
        //     this.walletAddress || '0x0000000000000000000000000000000000000000',
        //   ],
        // },
        // {
        //   ...stakeContract,
        //   functionName: 'getRewards',
        //   chainId: environment.eth.chainIdInt, // 17
        //   args: [
        //     this.walletAddress || '0x0000000000000000000000000000000000000000',
        //   ],
        // },
        // {
        //   ...stakeContract,
        //   functionName: 'rewardTokensPerBlock',
        //   chainId: environment.eth.chainIdInt,  // 18
        //   args: [],
        // },
        // {
        //   ...stakeContract,
        //   functionName: 'tokensStaked',
        //   chainId: environment.eth.chainIdInt,  // 19
        //   args: [],
        // },
        // {
        //   ...stakeContract,
        //   functionName: 'harvestLock',
        //   chainId: environment.eth.chainIdInt,    // 20
        //   args: [],
        // },

      ],
    };

    /**
     * Above contracts are going to be read.
     */

    this.presaleData = await readContracts(config);
    console.log('\n******presaleData =', this.presaleData, '\n******\n');

    // try {
    //   this.stakeData.userStaked = +formatEther(this.presaleData[16].result[0] || 0);
    //   this.stakeData.unlockTime = Number(this.presaleData[16].result[3]);
    //   this.stakeData.planId = Number(this.presaleData[16].result[4]);
    //   this.stakeData.userReward = +formatEther(this.presaleData[17]?.result || 0);
    //   this.stakeData.rewardPerRound = +formatEther(this.presaleData[18].result || 0);
    //   this.stakeData.totalStaked = +formatEther(this.presaleData[19].result || 0);
    //   this.stakeData.isClaimEnabled = !this.presaleData[20].result || false;
    //   this.stakeData.apy = +((+environment.apyCalcConst / this.stakeData.totalStaked) * 100).toFixed(0);
    // } catch (e) {
    //   console.log("Presale over.", e);
    // }


    this.balanceData.currentStep = Number(this.presaleData[5].result);
    this.balanceData.paused = this.presaleData[9].status === "success" ? this.presaleData[9].result : false;

    /* assigning the value of no of token sold va bsc and eth contract */
    this.balanceData.ethTokenSold = Number(this.presaleData[0].result);
    this.balanceData.bscTokenSold = Number(this.presaleData[10].result);
    // this.balanceData.polyTokenSold    = Number(this.presaleData[16].result);
    this.balanceData.totalTokensSold = this.balanceData.ethTokenSold + this.balanceData.bscTokenSold;
    this.stakeData.stackPercent = +((this.stakeData.totalStaked / this.balanceData.totalTokensSold) * 100).toFixed(0);

    const blockNumber = await this.viemClient.getBlockNumber();
    this.stakeData.rewardsPaid = +((Number(blockNumber) - 19617462) * 3938).toFixed(0); // (currentBlock - startBlock) * perwardsPerBlock;

    try {
      /* assigning the value of total USDT raised via eth and bsc */
      this.balanceData.oneEth = +formatEther(this.presaleData[1].result); // no of app tokens in exchange of 1 eth
      this.balanceData.ethUsdt = Number(this.presaleData[2].result); // no of app tokens in exchange of 1 Eth USDT
      this.balanceData.oneBnb = +formatEther(this.presaleData[11].result); // no of app tokens in exchange of 1 eth
      this.balanceData.bnbUsdt = Number(this.presaleData[12].result);      // no of app tokens in exchange of 1 Eth USDT
      // this.balanceData.onePoly = +formatEther(this.presaleData[17].result); // no of app tokens in exchange of 1 eth
      // this.balanceData.polyUsdt = Number(this.presaleData[18].result);      // no of app tokens in exchange of 1 Eth USDT
    } catch (e) {
      console.log("Presale over.", e);
    }


    this.balanceData.usdRaised = +formatEther(this.presaleData[3].result || 0) + +formatEther(this.presaleData[13].result || 0) + this.balanceData.usdRaisedSol + this.balanceData.usdRaisedSolDirect; //static val


    /* assigning the value of total token purchased by the current user */
    this.balanceData.ethClaimable = +formatEther(this.presaleData[8].result || 0);
    this.balanceData.bscClaimable = +formatEther(this.presaleData[15].result || 0);
    // this.balanceData.polyClaimable = +formatEther(this.presaleData[21].result || 0);
    this.balanceData.userClaimable = this.balanceData.ethClaimable + this.balanceData.bscClaimable;

    /**
     * Unsold token adjustment section.
     * if there is any mismatched from the block
     * chain end
     */

    const skipIndexEth: Array<any> = [];
    const skipIndexBsc: Array<any> = [];
    // const skipIndexPol: Array<any> = [];

    const ethUnsoldArr = this.skipIndexUpdate(this.presaleData[7].result, skipIndexEth);
    // pushing additional value to adjust the unsold token if required
    // ethUnsoldArr.splice(<index>, 0, <new value>);
    const bnbUnsoldArr = this.skipIndexUpdate(this.presaleData[14].result, skipIndexBsc);
    // const polUnsoldArr = this.skipIndexUpdate(this.presaleData[20].result, skipIndexPol);

    // console.log('ethUnsoldArr = ', ethUnsoldArr);
    // console.log('bnbUnsoldArr = ', bnbUnsoldArr);
    // console.log('polUnsoldArr = ', polUnsoldArr);


    let counter = 0;
    let unsoldAmt = 0;

    for (let time of this.presaleData[6].result) {
      let roundInfo = this.tokenomics[counter];
      this.tokenomics[counter].endDate = Number(time) * 1000;

      //TODO: revisit this on round 3
      const ethVal = ethUnsoldArr[counter] || 0;
      const bscVal = bnbUnsoldArr[counter] || 0;
      // const polVal = polUnsoldArr[counter] || 0;


      if (counter < this.balanceData.currentStep) {
        let roundUnSold = (roundInfo.maxToken - roundInfo.minToken) - (ethVal + bscVal);
        roundUnSold = roundUnSold * roundInfo.tokenUSDT;
        unsoldAmt = unsoldAmt + roundUnSold;
        this.tokenomics[counter].maxAmount = Math.ceil(this.tokenomics2[counter].maxAmount + unsoldAmt);
        this.tokenomics[counter + 1].maxAmount = Math.ceil(this.tokenomics2[counter + 1].maxAmount + unsoldAmt);
      }
      counter++;
    }


    const tokenBuyLimit = Number(this.presaleData[4].result);
    const availableToken = this.tokenomics[this.balanceData.currentStep].maxToken - this.soldToken;
    this.balanceData.maxTokensToBuy = availableToken > tokenBuyLimit ? tokenBuyLimit : availableToken;

    // console.log('\n******\n RPC balanceData =', this.balanceData, '\n******\n');
    // console.log('\n******\n RPC tokenomics =', this.tokenomics, '\n******\n');

    if (this.wagmiData?.isConnected) this.getMetaData(); // if user is connected then call this function
    if (this.refreshId) clearTimeout(this.refreshId);
    this.refreshId = setTimeout(() => this.getPresalesData(), this.refreshRate); // Call the self method on each 5secs interval

  }

  /**
   * method to adjust unsold token
   * @param arr the array that need to be adjusted
   * @param skipIndex index no that need to be ignored or skipped
   * @returns a fresh set of array after all adjustment
   */

  skipIndexUpdate(arr: Array<any>, skipIndex: Array<any>): Array<any> {
    let newArr: any = [];
    for (let i = 0; i < arr.length; i++) {
      if (!skipIndex.includes(i)) {
        newArr.push(Number(arr[i]));
      }
    }
    return newArr;
  }


  /**
   * This method is used to fetch the native balance
   * of the current account and network
   */

  async getMetaData() {
    const nativeBalance = await fetchBalance({
      address: this.walletAddress as any,
      formatUnits: 'ether',
    });

    const usdtBal = await fetchBalance({
      address: this.walletAddress as any,
      // formatUnits: 'mwei',
      token: this.getChainData().usdTAddress as any,
      chainId: this.getChainData().chainIdInt,
    });

    /* user's Eth/Bnb and USDT balance */
    this.balanceData.nativeBal = +nativeBalance.formatted;
    this.balanceData.usdtBal = +usdtBal.formatted;

  }

  /**
   * returning no of total sold tokens
   */
  get soldToken() {
    return this.balanceData.totalTokensSold;
  }

  isEthChain(): boolean {
    return this.chainId === environment.eth.chainIdInt ? true : false;
  }

  /**
   * switching the current network. If user is on wrong network
   * and got error on switching then a popup
   * will appear saying to connect correct chain
   */
  async switchNetwork(chainId: number) {
    await switchNetwork({
      chainId: chainId,
    }).then((success: any) => {
      this.appRef.tick();
    },
      (err: any) => {
        const chain = environment.eth;
        const chainName = chain.chainInfo.params[0].chainName;
        this.popupService.messagePopup('info', this.transletService.instant('switchNetwork', { chain_name: chainName, }));
      }
    );
  }

  /**
   * calculating the available no of token in exchange of inserted Eth amount
   */

  getNativeAmount = async (amount: number) => {
    let singleAmount = 0;
    switch (this.getChainData().chainIdInt) {
      case environment.eth.chainIdInt:
        singleAmount = this.balanceData.oneEth;
        break;

      case environment.bsc.chainIdInt:
        singleAmount = this.balanceData.oneBnb;
        break;

      // case environment.poly.chainIdInt:
      //   singleAmount = this.balanceData.onePoly;
      //   break;

      default:
        singleAmount = this.balanceData.oneEth;
    }
    return Math.floor(+(amount / +singleAmount));
  };

  /**
   * calculating the available no of token in exchange of inserted Usdt amount
   */

  getUSDTAmount = async (amount: number) => {
    let singleAmount = 0;
    switch (this.getChainData().chainIdInt) {
      case environment.eth.chainIdInt:
        singleAmount = this.balanceData.ethUsdt / Math.pow(10, 6);
        break;
      case environment.bsc.chainIdInt:
        singleAmount = this.balanceData.bnbUsdt / Math.pow(10, 18);
        break;
      // case environment.poly.chainIdInt:
      //   singleAmount = this.balanceData.ethUsdt / Math.pow(10, 6);
      //   break;
      default:
        singleAmount = this.balanceData.ethUsdt / Math.pow(10, 6);
    }
    return Math.floor(+(amount / +singleAmount));
  };


  /**
   * reverse calculation required amount (eth/bnb/usdt)
   * in exchange of inserted token
   * @param amount : no of token
   * @param mode : exchange mode
   * @returns : required amount
   */

  getDynamicAmount = async (amount: number, mode: string) => {
    let singleAmount = 0;

    switch (this.getChainData().chainIdInt) {
      case environment.eth.chainIdInt:
        if (mode === 'getNativeAmount') {
          singleAmount = this.balanceData.oneEth;
        } else {
          singleAmount = this.balanceData.ethUsdt / Math.pow(10, 6);
        }
        break;

      case environment.bsc.chainIdInt:
        if (mode === 'getNativeAmount') {
          singleAmount = this.balanceData.oneBnb;
        } else {
          singleAmount = this.balanceData.bnbUsdt / Math.pow(10, 18)
        } break;

      // case environment.poly.chainIdInt:
      //   if (mode === 'getNativeAmount') {
      //     singleAmount = this.balanceData.onePoly;
      //   } else {
      //     singleAmount = this.balanceData.polyUsdt / Math.pow(10, 6)
      //   } break;

      default:
        if (mode === 'getNativeAmount') {
          singleAmount = this.balanceData.oneEth;
        } else {
          singleAmount = this.balanceData.ethUsdt / Math.pow(10, 6);
        }
        break;
    }
    return +(amount * singleAmount);
  };

  /**
   * this function is swaping the native token with app token
   * @param amount: app token amount
   * @param value: eth token value
   */

  async swapNativeTokens(
    amount: number,
    value: number,
    isBuyStake: boolean = false
  ) {
    // setting swapStatus to not started
    this.swapStatus = SwapStatus.not_started;

    /**
     * preparing a contract for swaping via smart contract
     * method of ethBuyHelper
     */
    const { request } = await prepareWriteContract({
      ...this.getPresaleContract(),
      functionName: this.getChainData().nativeFunction,
      chainId: this.getChainData().chainIdInt,
      args: this.getChainData().hasStaking ? [amount, isBuyStake] : [amount],
      value: parseEther(`${value}`),
      account: this.walletAddress as any, // Optional param. Not required!
    });

    // setting swapStatus to confirmation pending
    this.swapStatus = SwapStatus.confirm_pending;

    /**
     * Writing contract for swap process
     */
    await writeContract(request).then(
      (success: any) => {
        console.log('Eth write Contract success', success);
        this.swapStatus = SwapStatus.in_progess;
        this.windowDataLayer('swap', 'confirmTransaction', 2, 0, 0);
        this.sendDashFx(this.getChainData().purchaseToken, amount, value);
        this.checkTansaction(success.hash, this.getChainData().purchaseToken, amount, value);
      },
      (err: any) => {
        console.log('swapNativeTokens Error =', err);
        if (err.code === 4001) {
          this.swapStatus = SwapStatus.rejected;
        } else {
          this.swapStatus = SwapStatus.failed;
        }
      }
    );
  }

  /**
   * this function is checking allowence
   * amount before swaping the usdt token with app token
   * @param amount: app token amount
   * @param value: usdt token value
   * @param afterAllowance: checking whether allowence is granted or not
   */

  async swapCryptoForUSDT(
    amount: number,
    value: number,
    afterAllowance: boolean = false,
    isBuyStake: boolean = false
  ) {
    /**
     * preparing token contract with ABI
     */
    const usdtContract = this.getUSDTContract();

    // set presale address based on current network
    const presaleAdd = this.getChainData().presaleAddress as any;

    /**
     * reading allowence contract
     */
    const allowanceData = await readContracts({
      contracts: [
        {
          ...usdtContract,
          functionName: 'allowance',
          chainId: this.chainId,
          args: [this.walletAddress, presaleAdd],
        },
      ],
    });

    //  checking provided allowence value in token contract

    let allowanceValue = Number(allowanceData[0].result) / Math.pow(10, 6);
    console.log('allowanceValue =', value, allowanceValue);

    /**
     * if inserted usdt value is larger than
     * provided allowence value then it needs further approval.
     * below we are preparing contract for allowence approval
     */

    if (+value > +allowanceValue) {
      const { request } = await prepareWriteContract({
        ...usdtContract,
        chainId: this.chainId,
        functionName: 'approve',
        args: [presaleAdd, afterAllowance || allowanceValue == 0 ? '100000000000000000000000000' : '0'],
        account: this.walletAddress as any, // Optional param. Not required!
      });

      // setting swapStatus to approval pending
      this.swapStatus = SwapStatus.approval_pending;

      // fetching transaction hash key of writing contract
      const { hash } = await writeContract(request);
      console.log('approve allowence hash key =', hash);

      /**
       * checking the approval transaction status.
       * if success and the allowence is preapproved
       * then it will call the buyUSDT function, else
       * will again go through the starting steps but this time with
       * allowence approval true mode.
       */
      await waitForTransaction({ hash }).then(
        (success: any) => {
          console.log('approve Config success', success);
          if (afterAllowance) {
            this.buyUSDT(amount, value, isBuyStake);
          } else {
            this.swapCryptoForUSDT(amount, value, true, isBuyStake);
          }
        },
        (err: any) => {
          console.log('approve Config error', err);
          this.swapStatus = SwapStatus.rejected;
        }
      );
    } else {

      /**
       * if inserted usdt value is smaller than
       * provided allowence value, then buyUSDT
       * function will be called and doesn't need
       * to check for allowence approval
       */
      this.buyUSDT(amount, value, isBuyStake);
    }
  }

  /**
   * this function is swaping the usdt token with app token
   * @param amount: app token amount
   * @param value: usdt token value
   * @param afterAllowance: checking whether allowence is granted or not
   */

  async buyUSDT(amount: number, value: number, isBuyStake: boolean = false) {
    /**
     * preparing a contract for swaping via smart contract
     * method of buyWithUSDT
     */

    console.log('buyUSDT called', this.getChainData().hasStaking)
    const contract = this.getPresaleContract();
    await prepareWriteContract({
      ...contract,
      functionName: 'buyWithUSDT',
      chainId: this.chainId,
      args: this.getChainData().hasStaking ? [amount, isBuyStake] : [amount],
      account: this.walletAddress as any, // Optional param. Not required!
    })
      // if prepare contract is success
      .then(
        async (request: any) => {
          // setting swapStatus to confirmation pending
          console.log('usdtBuyHelper =', request);
          this.swapStatus = SwapStatus.confirm_pending;

          /**
           * Once confirmed startig write contract
           */
          await writeContract(request.request).then(
            (success: any) => {
              console.log('buyUSDT writeContract success', success);
              this.windowDataLayer('swap', 'confirmTransaction', 2, 0, 0); // setting windowdatalayer for analytics
              this.sendDashFx('usdt', amount, value); // updating dashFx data
              this.swapStatus = SwapStatus.in_progess; // setting swapStatus to inprogress mode
              this.checkTansaction(success.hash, 'usdt', amount, value); // checking transaction status
            },
            (err: any) => {
              console.log('buyUSDT writeContract error', err);
              this.swapStatus = SwapStatus.failed;
            }
          );
        },

        /**
         * if there is any error while preparing contract
         * like requesting more than max-token buy limit
         * then a warning popup will appear saying that
         * the max-token limitation
         */
        (err: any) => {
          this.popupService.messagePopup(
            'warning',
            this.transletService.instant('widget.max_token_exceed', {
              maxTokensToBuy: this.balanceData.maxTokensToBuy,
            }),
            this.transletService.instant('widget.max_token_title')
          );
          this.swapStatus = SwapStatus.rejected;
          console.log(err.message);
        }
      );
  }

  /**
   * this function is check the final
   * status of the current transaction
   * @param haskKey: transaction hash key
   * @param mode: Eth/Bnb/Usdt transaction
   * @param amount: no of tooken
   * @param value:  amount of eth/bnb/usdt
   */

  async checkTansaction(
    haskKey: string,
    mode: string,
    amount: number,
    value: number,
    isDatalayer: boolean = true
  ) {
    await waitForTransaction({ hash: haskKey as any }).then(
      (response: any) => {
        console.log('checkTansaction success', response);
        this.getMetaData(); // update the current native balance
        this.swapStatus = SwapStatus.complete; // setting swapStatus to completed
        this.swapHash = response.transactionHash; // assigning succes transaction hash id
        this.sendBWData(mode, amount, value);
        if (isDatalayer) this.windowDataLayer('swap', 'swapSuccessful', 3, 1, 0, mode, environment.tokenName, value, amount);
      },
      (err: any) => {
        console.log('checkTansaction error', err);
        this.getMetaData(); // update the current native balance
        this.swapStatus = SwapStatus.failed; // setting swapStatus to failed
      }
    );
  }

  /**
   * function to update analytics data
   * @param flowName: process name like swap/connectWallet
   * @param stepName: swapSuccessful/confirmTransaction/successful
   * @param stepNo: step no of process
   * @param completeFlag: is omppletedd or not
   * @param errorCode: if any error
   * @param fromCurrency: ETH/BNB/USDT
   * @param toCurrency : ICO/APP token
   * @param fromVal: ETH/BNB/USDT value
   * @param toVal: App token value
   */
  async windowDataLayer(
    flowName: string,
    stepName: string,
    stepNo: number,
    completeFlag: number,
    errorCode: number,
    fromCurrency: string = '',
    toCurrency: string = '',
    fromVal: number = 0,
    toVal: number = 0
  ) {
    if (environment.production) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'workflowStep',
        walletAddress: this.walletAddress,
        workflowName: flowName, // "swap",
        workflowStepNumber: stepNo, // 2,
        workflowStepName: stepName, // "confirmTransaction",

        workflowCompleteFlag: completeFlag,
        workflowErrorCode: errorCode,

        transactionId: completeFlag === 0 ? undefined : this.swapHash,
        swapFromValue: completeFlag === 0 ? undefined : fromVal, // value,
        swapToValue: completeFlag === 0 ? undefined : toVal, // amount,
        swapFromCurrency: completeFlag === 0 ? undefined : fromCurrency, // "ETH/BNB/USDT",
        swapToCurrency: completeFlag === 0 ? undefined : toCurrency, // "token name"
        presaleStage: completeFlag === 0 ? undefined : this.balanceData.currentStep, // current running stage
        stageTokenValue: completeFlag === 0 ? undefined : this.tokenomics[this.balanceData.currentStep].tokenUSDT, // current running stage's token usdt value
        swapFromValueUsd: completeFlag === 0 ? undefined : await this.getDynamicAmount(toVal, 'getUSDTAmount'),
      });
    }
  }

  /**
   * DashFx is another analytics feature
   * @param type : connect wallet/ETH/BNB/USDT
   * @param toValue: App token value
   * @param fromValue : ETH/BNB/USDT value
   * @param connectMode: whether it is wallet connect or not
   */

  sendDashFx = (
    type: string,
    toValue: number,
    fromValue: number,
    connectMode: boolean = false
  ) => {
    if (environment.production && connectMode) {
      const tokens: any = this.balanceData.userClaimable || 0;
      const data = {
        walletAddress: this.walletAddress,
        iid: environment.iidDashFx,
        event: 'lead_success',
        purchaseType: connectMode ? null : type,
        purchaseTokens: +toValue,
        purchaseTypeAmount: +fromValue,
        purchaseUsdAmount: type === 'usdt' ? +fromValue : +(+(toValue * this.tokenomics[this.balanceData.currentStep].tokenUSDT).toFixed(2)),
        ipAddress: '',
        clickId: getParam('clickId'),
        source: getParam('source'),
        tid: getParam('tid'),
        pid: getParam('pid')
      };
      console.log('DashFx data =', tokens, data);
      this.http.post(environment.urlDashFx, data).subscribe(
        (res: any) => {
          console.log('DashFx success response', res);
        },
        (err: any) => {
          console.log('DashFx error response', err);
        }
      );
    }
  };


  /**
* BestWallet Data analytics
* @param type : connect wallet/ETH/BNB/USDT
* @param toValue: App token value
* @param fromValue : ETH/BNB/USDT value
* @param connectMode: whether it is wallet connect or not
*/

  sendBWData = (type: string, toValue: number, fromValue: number) => {
    if (environment.production) {
      const tokens: any = (this.balanceData.ethClaimable || 0);
      const data = {
        publicAddress: this.walletAddress,
        event: (tokens > toValue ? 'revenue' : 'conversion'),
        purchaseType: type,
        purchaseTokens: +toValue,
        purchaseTypeAmount: +fromValue,
        purchaseUsdAmount: type === 'usdt' ? +fromValue : +(+(toValue * this.tokenomics[this.balanceData.currentStep].tokenUSDT).toFixed(2)),
        ipAddress: '',
        clickId: getParam('clickId'),
        source: getParam('source'),
        hash: this.swapHash,
        chainId: this.chainId,
        presaleName: "$SEAL",
        isBW: this.isBW,
        rABsZdfEqoXvExie: getParamWithoutCookie('rABsZdfEqoXvExie') || undefined,
      }

      console.log('BW data =', tokens, data)
      this.http.post(environment.bwApiUrl, data).subscribe((res: any) => {
        console.log('BW success response', res);
      },
        (err: any) => {
          console.log('BW error response', err);
        });
    }
  }


  /**
   * Function to claim of purchased token by the user.
   * Once the presale stage is over, user can claim his token.
   * We are using eth based claim only. This function is
   * preparing a claim contract with a claim method.
   */

  async claim() {
    console.log('Claim called');
    /*const { request } = await prepareWriteContract({
      ...presaleContractEth,
      functionName: 'claim',
      chainId: environment.eth.chainIdInt,
      args: [],
      account: this.walletAddress as any, // Optional param. Not required!
    });

    await writeContract(request).then(
      (success: any) => {
        console.log('Claim success', success);
      },
      (err: any) => {
        console.log('Claim error', err);
      }
    );*/
  }

  /*refer() {
    if (!this.walletAddress || this.walletAddress == '') return;
    const data = {
      walletAddress: this.walletAddress,
      iid: environment.iidDashFx,
    };
    this.http.post(environment.referUrl, data).subscribe((res: any) => {
      console.log('ReferURL success response', res);
      this.referUrl = res.data.url;
    },
      (err: any) => {
        console.log('ReferURL error response', err);
      }
    );
  }*/

  async claimStake() {
    console.log('Claim called');
    this.swapStatus = SwapStatus.in_progess;
    const { request } = await prepareWriteContract({
      ...presaleContractEth,
      functionName: 'claimAndStake',
      chainId: environment.eth.chainIdInt,
      args: [],
      account: this.walletAddress as any, // Optional param. Not required!
    });
    await writeContract(request).then(
      (success: any) => {
        console.log('claimStake success', success);
        this.checkTansaction(success.hash, 'claimstake', 0, 0); // checking transaction status
      },
      (err: any) => {
        console.log('claimStake error', err);
        this.swapStatus = SwapStatus.failed;
      }
    );
  }

  async claimStakeRewards() {
    console.log('Claim called');
    const { request } = await prepareWriteContract({
      ...stakeContract,
      functionName: 'harvestRewards',
      chainId: environment.eth.chainIdInt,
      args: [],
      account: this.walletAddress as any, // Optional param. Not required!
    });
    await writeContract(request).then(
      (success: any) => {
        console.log('claimStake success', success);
      },
      (err: any) => {
        console.log('claimStake error', err);
      }
    );
  }

  async withdrawStake() {
    console.log('Claim called');
    const { request } = await prepareWriteContract({
      ...stakeContract,
      functionName: 'withdraw',
      chainId: environment.eth.chainIdInt,
      args: [],
      account: this.walletAddress as any, // Optional param. Not required!
    });
    await writeContract(request).then(
      (success: any) => {
        console.log('claimStake success', success);
      },
      (err: any) => {
        console.log('claimStake error', err);
      }
    );
  }

  async createStake(amount: any) {
    const allowanceData = await readContracts({
      contracts: [
        {
          ...tokenContract,
          functionName: 'allowance',
          chainId: this.chainId,
          args: [this.walletAddress, environment.stakingAddress],
        },
      ],
    });
    console.log('createStake allowanceData =', allowanceData);
    const allowanceValue = +formatEther(allowanceData[0].result as any);
    const amountEther = +amount;
    // const allowanceValue = allowanceData[0] as any;
    console.log(amountEther, allowanceValue, amount);

    if (amountEther > +allowanceValue) {
      const { request } = await prepareWriteContract({
        ...tokenContract,
        chainId: this.chainId,
        functionName: 'approve',
        args: [environment.stakingAddress, '100000000000000000000000000'],
        account: this.walletAddress as any, // Optional param. Not required!
      });

      console.log('approveConfig =', request);
      this.swapStatus = SwapStatus.approval_pending;
      const { hash } = await writeContract(request);
      console.log('createStake approveConfig hash =', hash);
      await waitForTransaction({ hash }).then(
        (success: any) => {
          console.log('approveConfig approve Config success', success);
          this.swapStatus = SwapStatus.confirm_pending;
          this.proceedCreateStake(amount);
        },
        (err: any) => {
          console.log('approve Config error', err);
          this.swapStatus = SwapStatus.rejected;
        }
      );
    } else {
      this.proceedCreateStake(amount);
    }
  }

  async proceedCreateStake(amount: any) {
    const { request } = await prepareWriteContract({
      ...stakeContract,
      functionName: 'deposit',
      chainId: environment.eth.chainIdInt,
      args: [parseEther(amount.toString())],
    });

    this.swapStatus = SwapStatus.confirm_pending;
    await writeContract(request).then(
      (success: any) => {
        console.log('proceedCreateStake success', success);
        this.swapStatus = SwapStatus.in_progess;
        this.checkTansaction(success.hash, '', amount, 0, false);
      },
      (err: any) => {
        console.log('Eth createStake error', err);
        this.swapStatus = SwapStatus.rejected;
      }
    );
  }


  /**
   * generate signature for wert transaction
   * @param amount Amount to spend
   * @returns signature data
   */
  async getABI(amount: number, isStake: boolean = false): Promise<string> {
    const data = encodeFunctionData({
      abi: presaleEth.abi,
      functionName: 'buyWithETHWert',
      args: [this.walletAddress, amount, isStake],
    });
    return data;
  }

  /**
   * Function to buy app token via wert widget.
   * this function convert the USDT value to app token
   * @param amount: Amount to spend
   * @returns : converted no of app tokens
   */

  getDynamicAmountWert = async (amount: number) => {
    console.log('getDynamicAmountWert', amount);
    const contractData = await readContracts({
      contracts: [
        {
          ...presaleContractEth,
          functionName: 'ethBuyHelper',
          chainId: environment.eth.chainIdInt,
          args: [amount],
        },
      ],
    });
    let ehtVal = +formatEther(contractData[0].result as any);
    console.log('return DynamicAmountWert', ehtVal);
    return +ehtVal;
  };

  getChainData() {
    switch (this.chainId) {
      case environment.eth.chainIdInt:
        return environment.eth;
      case environment.bsc.chainIdInt:
        return environment.bsc;
      // case environment.poly.chainIdInt:
      //   return environment.poly;
      default:
        return environment.eth;
    }
  }

  getPresaleContract() {
    switch (this.chainId) {
      case environment.eth.chainIdInt:
        return presaleContractEth;
      case environment.bsc.chainIdInt:
        return presaleContractBsc;
      // case environment.poly.chainIdInt:
      //   return presaleContractPoly;
      default:
        return presaleContractEth;
    }
  }

  getUSDTContract() {
    switch (this.chainId) {
      case environment.eth.chainIdInt:
        return ethUsdtContract;
      case environment.bsc.chainIdInt:
        return bscUsdtContract;
      // case environment.poly.chainIdInt:
      //   return polyUsdtContract;
      default:
        return ethUsdtContract;
    }
  }


  /**
* Setting program which will required 
* for every transaction
*/
  setProgram(): void {
    this.program = new Program(presaleSol, this.programId, this.provider);
    // console.log('programme =', this.program);
    this.getRequiredAddress();
  }

  /**
  * fething required address to get all contract data
  */

  async getRequiredAddress() {
    // ======= through this address we can get the necessary data of smartContract ==========
    this.stateInfoAddress = PublicKey.findProgramAddressSync(
      [Buffer.from("state_info")],
      this.programId
    );
    this.getPresaleDataSol();
  }

  /**
  * detect current window object
  */

  getWindowObject(walletName: string = '') {
    switch (walletName !== '' ? walletName : this.curWalletName) {
      case 'Phantom': return window.solana;
      case 'Solflare': return window.solflare;
      // case 'Coinbase': return window.coinbaseSolana;
      // case 'Trust': return window.trustWallet.solana;
      default: return window.solana;
    }
  }

  // ------- get presale data ---------

  async getPresaleDataSol() {
    try {

      const stateInfo = await this.program.account.stateInfo.fetch(this.stateInfoAddress[0]);

      this.balanceData.usdRaisedSol = (Number(stateInfo.totalUsdRaised) / 1000000);
      let balance = await this.connection.getBalance(new PublicKey(environment.solana.directAddress));
      balance = balance / 1000000000;
      this.balanceData.usdRaisedSolDirect = balance * this.price;
      console.log('\n **** balanceData data ****** ', this.balanceData);
      console.log('\n **** balanceData data ****** ', this.balanceData.usdRaised + this.balanceData.usdRaisedSol);
    } catch (e) {
      console.log(e);
    }

  }
}
